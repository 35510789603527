<template>
  <v-card
    class="glass-block mt-5 pa-0 elevation-12 loginCard" outlined
    shaped
  >
    <v-card-title class="text-uppercase ">
      <div class="title">LivePerson Status Dashboard</div>
    </v-card-title>
    <v-card-subtitle>Monitor and check on the availability of your LivePerson services</v-card-subtitle>
    <v-form autocomplete="on" ref="form" @submit.prevent="validate">
    <v-card-text>
      <v-text-field
        v-model.trim="accountID"
        placeholder="ex. 53767309"
        label="My LivePerson Account"
        name="My LivePerson Account"
        color="secondary"
        :rules="rules"
        outlined
        filled
        append-icon="mdi-account"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="accent"
          type="submit"
          :loading="isLoading"
      >
        <v-icon left>mdi-view-dashboard-outline</v-icon>
        View Status
      </v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

export default {
  name: 'Login',
  data: () => ({
    accountID: '',
    rules: [
      v => !!v || 'This field is required'
    ],
    isLoading: false
  }),
  methods: {

    async validate () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        setTimeout(() => {
          this.$router.push(`/dashboard/${this.accountID}`)
        }, 500)
      }
    }
  }
}
</script>

<style scoped lang="scss">
$xsmall: 300px;
$small: 600px;

.loginCard{
  width: 50%;
  margin: auto !important;

  @media screen and (min-width: $xsmall) and (max-width: $small) {
    width: 80%;
  }
  }



</style>
